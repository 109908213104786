<template lang="pug">
div.view_container
  Header
  div.view_title  Update Your Info
  div.input_container
    input(v-model="firstName" type="text" placeholder="First name")
  div.input_container
    input(v-model="lastName" type="text" placeholder="Last name")
  br
  br
  button.cta(v-on:click="updateInfo") {{buttonTitle}}
</template>

<script>
/* global gtag */
import { UPDATE_INFO_MUTATION } from '../graphql/mutations'
import { ME_QUERY } from '../graphql/queries'

import Header from '../components/Header'

export default {
  name: 'UpdateInfo',
  props: {

  },
  components: {
    Header,
  },
  watch: {
    me: function() {
      this.firstName = this.me.firstName
      this.lastName = this.me.lastName
    },
  },
  computed: {
    buttonTitle () {
      return this.loading ? "Updating..." : "Update"
    }
  },
  data() {
    return {
      loading: false,
      firstName: null,
      lastName: null,
    }
  },
  apollo: {
    me () {
      return {
        query: ME_QUERY,
        // Disable the query
        fetchPolicy: 'network-only',
      }
    },
  },
  methods: {
    updateInfo: function() {
      if (!this.firstName || this.firstName == "") {
        this.$toast.error("Please provide your first name")
        return
      } else if (!this.lastName || this.lastName == "") {
        this.$toast.error("Please provide your last name")
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: UPDATE_INFO_MUTATION,
        variables: {
          firstName: this.firstName,
          lastName: this.lastName,
        }
      }).then((data) => {
        this.loading = false
        var user = data.data.updateInfo.user
        const token = data.data.updateInfo.token
        var errors = data.data.updateInfo.errors
        this.errorsToast(errors)

        if (token) {
          this.$store.commit('setToken',token)
        }

        if (user) {
          this.$store.dispatch('signIn',user)
          this.$router.push({name:"CreateOrUpdateProject"})
        }

      }).catch((error) => {
        this.loading = false
        this.errorToast(error)
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.updateInfo()
      }
    }
  },
  mounted: function () {
    // window.vue = this

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/signup'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.legal_text {
  margin: 12px 8px 36px 8px;
  font-family: gibsonregular;
  font-size: 18px;
  color: #ADAAB6;
}
.legal_link {
  color: #ADAAB6;
  text-decoration: none;
  font-weight: bold;
}
.legal_link:hover {
  text-decoration: underline;
  color: #6e6d7a;
  text-align:  center;
}
.or {
  margin: 30px 12px 20px 12px;
  border: none;
  font-family: gibsonregular;
  font-size: 20px;
}
</style>
